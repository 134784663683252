import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class OpeneyeupdateService {
  constructor(private readonly updates: SwUpdate) {
    console.log('subscribe to updated available');
    this.updates.available.subscribe((event) => {
      this.showAppUpdateAlert();
    });
  }
  showAppUpdateAlert() {
    alert('A new app version has been published;');
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
