import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import {
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { EmployeeloginComponent } from '../components/employeelogin/employeelogin.component';

import { environment } from '../../environments/environment';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user = null;
  public openEYEModules = null;
  public token = null;
  public tokenId = null;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private router: Router
  ) {}

  loadStorage = async () => {
    await this.storage.create();
    if (!this.token) {
      this.token = await this.storageGet('token');
    }
    if (!this.tokenId) {
      this.tokenId = await this.storageGet('tokenId');
    }
    if (!this.user) {
      this.user = await this.storageGet('user');
    }
    if (!this.openEYEModules) {
      this.openEYEModules = await this.storageGet('openEYEModules');
    }
  };

  storageGet = async (key: string) => {
    const value = await this.storage.get(key);
    return value;
  };

  storageSet = async (key: string, value: any) => {
    await this.storage.set(key, value);
  };

  storageDelete = async (key: string) => {
    await this.storage.remove(key);
  };

  storageClear = async () => {
    this.storage.clear();
  };

  isLoggedIn = async (moduleCode = null) => {
    await this.loadStorage();

    if (this.token) {
      if (moduleCode) {
        const result: any = await this.http
          .get(`${environment.v8}/sysadmin/accesslevel/${moduleCode}`, {
            headers: new HttpHeaders({
              Authorization: `Bearer ${this.token}`,
            }),
          })
          .toPromise();
        if (result.accessLevel == 0) {
          Swal.fire({
            icon: 'error',
            heightAuto: false,
            title: 'Access restricted',
          });
          this.navCtrl.navigateRoot('home');
        }
        return result.accessLevel;
      }
      return this.user;
    }

    const modal = await this.modalCtrl.create({
      component: EmployeeloginComponent,
      showBackdrop: false,
      backdropDismiss: false,
      swipeToClose: false,
      keyboardClose: false,
    });
    await modal.present();
    await modal.onDidDismiss();
    return this.user;
  };

  login = async (userName: string, password: string) => {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();
    try {
      const result: any = await this.http
        .post(`${environment.v8}/employee/login`, {
          userName,
          password,
          openEyeVersion: environment.version,
        })
        .toPromise();
      loading.dismiss();

      this.user = {
        ...result.employee.userData,
        userId: result.employee.userId,
        picture: `${environment.baseDomain}/${result.employee.userData.picture}`,
      };
      await this.storageSet('user', this.user);

      this.openEYEModules = result.employee.openEYEModules;
      await this.storageSet('openEYEModules', this.openEYEModules);

      this.token = result.token;
      await this.storageSet('token', this.token);
      this.tokenId = result.tokenId;
      await this.storageSet('tokenId', this.tokenId);

      return true;
    } catch (error) {
      console.log(error.status);
      loading.dismiss();
      Swal.fire({
        icon: 'error',
        heightAuto: false,
        title: 'Invalid username/password',
      });
      return false;
    }
  };

  logout = async () => {
    try {
      const token = this.token;
      this.token = null;
      this.tokenId = null;
      this.user = null;
      this.openEYEModules = null;
      this.storageClear();
      this.http
        .get(`${environment.v8}/employee/logout`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        })
        .toPromise();
      this.navCtrl.navigateRoot('/login');
    } catch (error) {
      console.error(error);
    }
  };

  getSystemSettings = async (settingName = 'X') => {
    try {
      const result = await this.http
        .get(`${environment.v8}/misc/systemsetting/${settingName}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  getSupportLists = async (lists) => {
    try {
      const result: any = await this.http
        .get(`${environment.v8}/misc/list/${lists}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error.error);
      this.handleHttpError(error);
      return false;
    }
  };

  async getSchema(path = '') {
    try {
      const result: any = await this.http
        .get(`${environment.v8}/${path}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error.error);
      this.handleHttpError(error);
      return false;
    }
  }

  async getRoster() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();

    try {
      const result: any = await this.http
        .get(
          `${environment.baseDomain}/utils/index.php/v8/hr/roster/${this.tokenId}`
        )
        .toPromise();
      loading.dismiss();
      return result;
    } catch (error) {
      console.error(error.error);
      loading.dismiss();
      this.handleHttpError(error);
      return false;
    }
  }

  handleHttpError(error) {
    switch (error.status) {
      case 500:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Server error',
          text: error.error,
        });
        break;
      case 401:
        this.logout();
        break;
      case 403:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Resource is forbidden',
          text: error.error,
        });
        break;
      case 404:
        this.navCtrl.navigateForward('/notfound');
        break;
      // case 426:
      //   this.updateApp();
      //   break;
    }
  }

  getPreviousPath() {
    try {
      if (this.router.getCurrentNavigation().previousNavigation) {
        return this.router
          .getCurrentNavigation()
          .previousNavigation.finalUrl.toString();
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
