import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'myprofile',
    loadChildren: () =>
      import('./pages/myprofile/myprofile.module').then(
        (m) => m.MyprofilePageModule
      ),
  },
  {
    path: 'notfound',
    loadChildren: () =>
      import('./pages/notfound/notfound.module').then(
        (m) => m.NotfoundPageModule
      ),
  },
  {
    path: 'optica/home/:requeryby/:data',
    loadChildren: () =>
      import('./pages/optical/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'optica/home',
    loadChildren: () =>
      import('./pages/optical/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'optica/neworder',
    loadChildren: () =>
      import('./pages/optical/neworder/neworder.module').then(
        (m) => m.NeworderPageModule
      ),
  },
  {
    path: 'optica/neworder/:_pubpid',
    loadChildren: () =>
      import('./pages/optical/neworder/neworder.module').then(
        (m) => m.NeworderPageModule
      ),
  },
  {
    path: 'optica/sale/:_id',
    loadChildren: () =>
      import('./pages/optical/sale/sale.module').then((m) => m.SalePageModule),
  },
  {
    path: 'optica/cpts',
    loadChildren: () =>
      import('./pages/optical/cptlist/cptlist.module').then(
        (m) => m.CptlistPageModule
      ),
  },
  {
    path: 'optica/reports',
    loadChildren: () =>
      import('./pages/optical/reports/list/list.module').then(
        (m) => m.ListPageModule
      ),
  },
  {
    path: 'optica/reports/collectionsbyseller',
    loadChildren: () =>
      import(
        './pages/optical/reports/collectionsbyseller/collectionsbyseller.module'
      ).then((m) => m.CollectionsbysellerPageModule),
  },
  {
    path: 'optica/reports/collectionsbyemployee',
    loadChildren: () =>
      import(
        './pages/optical/reports/collectionsbyemployee/collectionsbyemployee.module'
      ).then((m) => m.CollectionsbyemployeePageModule),
  },
  {
    path: 'optica/reports/ordersbyemployee',
    loadChildren: () =>
      import(
        './pages/optical/reports/ordersbyemployee/ordersbyemployee.module'
      ).then((m) => m.OrdersbyemployeePageModule),
  },
  {
    path: 'optica/reports/officeprofit',
    loadChildren: () =>
      import('./pages/optical/reports/officeprofit/officeprofit.module').then(
        (m) => m.OfficeprofitPageModule
      ),
  },
  {
    path: 'optica/reports/ordersbyinsurance',
    loadChildren: () =>
      import(
        './pages/optical/reports/ordersbyinsurance/ordersbyinsurance.module'
      ).then((m) => m.OrdersbyinsurancePageModule),
  },
  {
    path: 'optica/reports/ordersbyselfpay',
    loadChildren: () =>
      import(
        './pages/optical/reports/ordersbyselfpay/ordersbyselfpay.module'
      ).then((m) => m.OrdersbyselfpayPageModule),
  },
  {
    path: 'optica/reports/orderswithbalance',
    loadChildren: () =>
      import(
        './pages/optical/reports/orderswithbalance/orderswithbalance.module'
      ).then((m) => m.OrderswithbalancePageModule),
  },
  {
    path: 'optica/reports/ordersbystatus',
    loadChildren: () =>
      import(
        './pages/optical/reports/ordersbystatus/ordersbystatus.module'
      ).then((m) => m.OrdersbystatusPageModule),
  },
  {
    path: 'optica/reports/salesbyvendor',
    loadChildren: () =>
      import('./pages/optical/reports/salesbyvendor/salesbyvendor.module').then(
        (m) => m.SalesbyvendorPageModule
      ),
  },
  {
    path: 'optica/reports/invoiceswithbalance',
    loadChildren: () =>
      import(
        './pages/optical/reports/invoiceswithbalance/invoiceswithbalance.module'
      ).then((m) => m.InvoiceswithbalancePageModule),
  },
  {
    path: 'optica/reports/orderswithitempending',
    loadChildren: () =>
      import(
        './pages/optical/reports/orderswithitempending/orderswithitempending.module'
      ).then((m) => m.OrderswithitempendingPageModule),
  },
  {
    path: 'inventory/product',
    loadChildren: () =>
      import('./pages/inventory/productlist/productlist.module').then(
        (m) => m.ProductlistPageModule
      ),
  },
  {
    path: 'inventory/product/:_id',
    loadChildren: () =>
      import('./pages/inventory/product/product.module').then(
        (m) => m.ProductPageModule
      ),
  },
  {
    path: 'inventory/product/:_id/:_office/inventory',
    loadChildren: () =>
      import('./pages/inventory/productinvrpt/productinvrpt.module').then(
        (m) => m.ProductinvrptPageModule
      ),
  },
  {
    path: 'inventory/product/:_id/:_office/sales',
    loadChildren: () =>
      import('./pages/inventory/productsalerpt/productsalerpt.module').then(
        (m) => m.ProductsalerptPageModule
      ),
  },
  {
    path: 'inventory/movements',
    loadChildren: () =>
      import('./pages/inventory/movements/movements.module').then(
        (m) => m.MovementsPageModule
      ),
  },
  {
    path: 'inventory/movement',
    loadChildren: () =>
      import('./pages/inventory/inventorycapture/inventorycapture.module').then(
        (m) => m.InventorycapturePageModule
      ),
  },
  {
    path: 'inventory/currentinventory',
    loadChildren: () =>
      import('./pages/inventory/currentinventory/currentinventory.module').then(
        (m) => m.CurrentinventoryPageModule
      ),
  },
  {
    path: 'inventory/inventorycount',
    loadChildren: () =>
      import('./pages/inventory/inventorycount/inventorycount.module').then(
        (m) => m.InventorycountPageModule
      ),
  },
  {
    path: 'inventory/movement/:_id',
    loadChildren: () =>
      import('./pages/inventory/inventorycapture/inventorycapture.module').then(
        (m) => m.InventorycapturePageModule
      ),
  },
  {
    path: 'product/labels',
    loadChildren: () =>
      import('./pages/inventory/labels/labels.module').then(
        (m) => m.LabelsPageModule
      ),
  },
  {
    path: 'admin/lists',
    loadChildren: () =>
      import('./pages/admin/lists/list/list.module').then(
        (m) => m.ListPageModule
      ),
  },
  {
    path: 'admin/list/:_id',
    loadChildren: () =>
      import('./pages/admin/lists/edit/edit.module').then(
        (m) => m.EditPageModule
      ),
  },
  {
    path: 'admin/offices',
    loadChildren: () =>
      import('./pages/admin/offices/list/list.module').then(
        (m) => m.ListPageModule
      ),
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('./pages/admin/users/list/list.module').then(
        (m) => m.ListPageModule
      ),
  },
  {
    path: 'admin/faxserver',
    loadChildren: () =>
      import('./pages/admin/faxserver/faxserver.module').then(
        (m) => m.FaxserverPageModule
      ),
  },

  {
    path: 'costinvoice',
    loadChildren: () =>
      import('./pages/costinvoice/list/list.module').then(
        (m) => m.ListPageModule
      ),
  },
  {
    path: 'costinvoice/paymultiple',
    loadChildren: () =>
      import('./pages/costinvoice/paymultiple/paymultiple.module').then(
        (m) => m.PaymultiplePageModule
      ),
  },

  {
    path: 'costinvoice/:_id',
    loadChildren: () =>
      import('./pages/costinvoice/costinvoice/costinvoice.module').then(
        (m) => m.CostinvoicePageModule
      ),
  },
  {
    path: 'costinvoice/:_id/:_office/:_linkTo/:_linkToId',
    loadChildren: () =>
      import('./pages/costinvoice/costinvoice/costinvoice.module').then(
        (m) => m.CostinvoicePageModule
      ),
  },
  {
    path: 'vendor',
    loadChildren: () =>
      import('./pages/vendor/list/list.module').then((m) => m.ListPageModule),
  },
  {
    path: 'documents/signature/:_id',
    loadChildren: () =>
      import('./pages/documents/signature/signature.module').then(
        (m) => m.SignaturePageModule
      ),
  },
  {
    path: 'documents/preview/:_id',
    loadChildren: () =>
      import('./pages/documents/preview/preview.module').then(
        (m) => m.PreviewPageModule
      ),
  },
  {
    path: 'documents/signpending',
    loadChildren: () =>
      import('./pages/documents/signpending/signpending.module').then(
        (m) => m.SignpendingPageModule
      ),
  },
  {
    path: 'documents/signpending/:_source',
    loadChildren: () =>
      import('./pages/documents/signpending/signpending.module').then(
        (m) => m.SignpendingPageModule
      ),
  },
  {
    path: 'roster',
    loadChildren: () =>
      import('./pages/roster/roster.module').then((m) => m.RosterPageModule),
  },
  {
    path: 'openemr/calendar',
    loadChildren: () =>
      import('./pages/openemr/calendar/calendar.module').then(
        (m) => m.CalendarPageModule
      ),
  },
  {
    path: 'openemr/patient/:_pid/ov/:_encounter',
    loadChildren: () =>
      import('./pages/openemr/ovs/ovs.module').then((m) => m.OvsPageModule),
  },
  {
    path: 'openemr/patient/:_pid',
    loadChildren: () =>
      import('./pages/openemr/patient/patient.module').then(
        (m) => m.PatientPageModule
      ),
  },
  {
    path: 'openemr/doctosign',
    loadChildren: () =>
      import('./pages/openemr/doctosign/doctosign.module').then(
        (m) => m.DoctosignPageModule
      ),
  },
  {
    path: 'openemr/signdoc',
    loadChildren: () =>
      import('./pages/openemr/signdoc/signdoc.module').then(
        (m) => m.SigndocPageModule
      ),
  },
  {
    path: 'hr/clockinout',
    loadChildren: () =>
      import('./pages/hr/clockinout/clockinout.module').then(
        (m) => m.ClockinoutPageModule
      ),
  },
  {
    path: 'hr/mytimecard',
    loadChildren: () =>
      import('./pages/hr/mytimecard/mytimecard.module').then(
        (m) => m.MytimecardPageModule
      ),
  },
  {
    path: 'fax',
    loadChildren: () =>
      import('./pages/fax/fax.module').then((m) => m.FaxPageModule),
  },
  {
    path: 'appupdate',
    loadChildren: () =>
      import('./pages/appupdate/appupdate.module').then(
        (m) => m.AppupdatePageModule
      ),
  },
  {
    path: 'optica',
    redirectTo: 'optica/home',
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'notfound',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
