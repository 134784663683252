import { Component, QueryList, ViewChildren } from '@angular/core';
import { IonAccordionGroup } from '@ionic/angular';
import { UserService } from './services/user.service';
import { OpeneyeupdateService } from './services/openeyeupdate.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //get access to the accordion group accordionGroupAdminMenu
  @ViewChildren('accordionGroupMenu')
  accordions: QueryList<IonAccordionGroup>;

  AppVersion = '0.0.0';

  constructor(
    public userSrvc: UserService,
    private openupdateSrvc: OpeneyeupdateService
  ) {
    this.AppVersion = environment.version;
  }

  doLogout() {
    this.userSrvc.logout();
  }

  toggleAccordion = (exclude: string = null) => {
    this.accordions.map((accordionGrp) => {
      const nativeEl = accordionGrp;
      if (nativeEl.value !== exclude) {
        nativeEl.value = undefined;
      }
    });
  };

  updateApp() {
    document.location.reload();
  }
}
